import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Paper, Typography, Button, Box} from '@material-ui/core';
import {Info as InfoIcon} from '@material-ui/icons';
import './popup.css';

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    marginBottom: theme.spacing(2), // Add margin between icon and text
  },
  icon: {
    fontSize: '5rem', // Adjust the size of the icon as needed
    color: '#2196f3', // Set the desired color for the icon
  },
}));

const Popup = ({open, title, message, onClose}) => {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <Paper className="popupCenter">
      <Box className="popupWrapper">
        <InfoIcon className="popupIcon" />
      </Box>
      <Typography variant="h5" className="popupTitle">
        {title}
      </Typography>
      <Typography variant="body1" style={{textAlign: 'center'}}>
        {message}
      </Typography>
      <Box className="popupButton">
        <Button
          variant="contained"
          style={{
            backgroundColor: '#2D9CDB',
            color: '#FFFFFF',
            padding: '11px 25px',
          }}
          onClick={onClose}
        >
          Ok, got it
        </Button>
      </Box>
    </Paper>
  );
};

export default Popup;
