import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {SvgIcon, Typography} from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  iconCircle: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
  },
  success: {
    border: `2px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
  },
  failure: {
    border: `2px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
}));

function PaymentStatus() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myQueryParam = queryParams.get('user');
  const classes = useStyles();
  const [isSuccess, setIsSuccess] = useState();
  const [isCompletedApiCall, setIsCompletedApiCall] = useState(undefined);

  const fetchData = async paramsData => {
    console.log(paramsData);
    const inputData = {
      userId: paramsData[0],
      planId: paramsData[1],
      merchantTransactionId: paramsData[2],
    };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/checkPaymentStatus`, inputData)
      .then(res => {
        const {data} = res;
        if (data.success && data.code === 'PAYMENT_SUCCESS') {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      })
      .catch(err => {
        setIsSuccess(false);
        console.error(err);
      });
    setIsCompletedApiCall(true);
  };

  useEffect(() => {
    const paramsData = myQueryParam.split('|');
    if (paramsData && paramsData.length === 3) {
      fetchData(paramsData);
    }
  }, [myQueryParam]);
  if (isCompletedApiCall !== undefined) {
    return (
      <div className={classes.root}>
        <div className={`${classes.iconCircle}`}>
          {isSuccess ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="#28a745"
                stroke-width="2"
                fill="none"
              />
              <path
                d="M9 13l2 2 6-6"
                stroke="#28a745"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="#dc3545"
                stroke-width="2"
                fill="none"
              />
              <path
                d="M6 8l12 8M6 16l12-8"
                stroke="#dc3545"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
        <Typography variant="h5">
          {isSuccess ? 'Payment Successful' : 'Payment Failed'}
        </Typography>
      </div>
    );
  } else {
    return <></>;
  }
}

export default PaymentStatus;
