import React, {useState} from 'react';
import {Button, Container, Grid, Typography} from '@mui/material';
import {QrReader} from 'react-qr-reader';
import {useNavigate} from 'react-router-dom';

const ScanBagsQr = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState('No QR code detected');

  const handleScan = data => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = error => {
    console.error(error);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{textAlign: 'center', marginTop: '2rem', overflowX: 'hidden'}}
    >
      <Typography variant="h4" gutterBottom>
        QR Code Scanner
      </Typography>
      <QrReader
        scanDelay={500}
        onResult={(result, error) => {
          if (!!result) {
            setResult(result?.text);
            navigate(`/${result?.text}`);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        constraints={{facingMode: 'environment'}}
        style={{width: '100%'}}
      />
      <Grid>
        <Typography
          variant="body1"
          sx={{
            marginTop: '1rem',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {result}
        </Typography>
      </Grid>
      {/* 
      <Button
        variant="contained"
        color="primary"
        sx={{marginTop: '1rem'}}
        onClick={() => setResult('No QR code detected')}
      >
        Clear Result
      </Button> */}
    </Container>
  );
};

export default ScanBagsQr;
