import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import React from 'react';
import Icon from '@mui/material/Icon';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import {shadows} from '@mui/system';
import ApexCharts from 'apexcharts';

import {makeStyles} from '@mui/styles';
import {
  Box,
  TextField,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import Popup from '../../../components/teamCard/Popup';
import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles({
  square: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    width: '70px !important',
    height: '40px !important',
    backgroundColor: 'black !important',
    color: 'white !important',
    marginBottom: '20px !important',
    marginTop: '10px !important',
  },
});

const StyledTab = styled(Tab)(({theme}) => ({
  borderRadius: 0,
  backgroundColor: 'white',
  height: '33px',
  width: '50%',
  minHeight: '33px',
  textTransform: 'none',
  color: theme.palette.primary.main,
  '&.Mui-selected': {
    backgroundColor: '#2D9CDB',
    color: 'white',
    borderRadius: '5px',
  },
}));

function TabPanel({value, index, children}) {
  return (
    <Box hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </Box>
  );
}

export default function BagSpecifications({userBagsObject}) {
  console.log(userBagsObject);
  const [showPopup, setShowPopup] = useState(false);
  const [hasValueGreaterThanZero, setHasValueGreaterThanZero] = useState(false);
  const [chartOptions, setChartOptions] = useState({});

  const [value, setValue] = useState(0);
  const [popUpTitle, setPopupTitle] = useState('');
  const [popUpMessage, setPopupMessage] = useState('');
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const chartOptions = {
  //   chart: {
  //     type: 'donut',
  //   },
  //   series: Object.values(userBagsObject?.bagFlavour),
  //   labels: Object.keys(userBagsObject?.bagFlavour),
  //   colors: ['#A155B9', '#165BAA', '#F765A3', '#16BFD6', '#1DDD8D'],
  //   dataLabels: {
  //     enabled: false,
  //   },
  // };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleOpen = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const getFormattedDate = orderDate => {
    if (orderDate) {
      const date = new Date(
        orderDate.seconds * 1000 + orderDate.nanoseconds / 1000000,
      );
      const formattedDate =
        date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear(); //  change the format to your preferred date format
      return formattedDate;
    }
    return '';
  };

  // #####################################

  const options = {
    fill: {
      opacity: 1,
    },
    chart: {
      type: 'radar',
    },
    series: [
      {
        name: 'Product 1',
        data: [90, 52, 38, 24, 33, 10],
      },
      {
        name: 'Product 2',
        data: [26, 21, 20, 6, 8, 15, 20],
      },
      {
        name: 'Product 3',
        data: [45, 17, 18, 10, 48, 68, 20],
      },
      {
        name: 'Product 4',
        data: [65, 85, 25, 15, 45, 35, 20],
      },
    ],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
    },
  };

  // const chart = new ApexCharts(document.querySelector("#chart"), options);

  // chart.render();
  // #####################################

  const handleSourcePopUpOpen = () => {
    setPopupTitle('Source Details');
    setPopupMessage(
      'Folders helps you keep inventory organised for each crop group.',
    );
    handleOpen();
  };

  const handleFlavourWheelPopUpOpen = () => {
    setPopupTitle('Flavour Wheel');
    setPopupMessage(
      'To keep Folders organised restrict the types of crops that will be added to the Folder. You can always add more later.',
    );
    handleOpen();
  };

  useEffect(() => {
    if (userBagsObject) {
      const falvourArray = Object.values(userBagsObject?.bagFlavour);
      const hasValueGreaterThanZero = falvourArray.some(value => value > 0);

      setHasValueGreaterThanZero(hasValueGreaterThanZero);
      const chartOptionsData = {
        chart: {
          type: 'donut',
        },
        series: Object.values(userBagsObject?.bagFlavour),
        labels: Object.keys(userBagsObject?.bagFlavour),
        colors: [
          '#A155B9',
          '#165BAA',
          '#F765A3',
          '#16BFD6',
          '#1DDD8D',
          '#7B48C1',
          '#1B478D',
          '#F95387',
          '#42C8E8',
          '#A2FFC3',
        ],
        dataLabels: {
          enabled: false,
        },
      };
      setChartOptions(chartOptionsData);
    }
  }, [userBagsObject]);

  return (
    <>
      <Grid container mt={3}>
        {userBagsObject?.bagImage ? (
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            style={{justifyContent: 'center'}}
          >
            {/* object fit cover fit */}
            <img
              src={userBagsObject?.bagImage}
              alt="My Image"
              style={{
                borderRadius: '25px',
                width: '100%',
                height: '300px',
                objectFit: 'cover',
              }}
            />
          </Grid>
        ) : null}
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        style={{marginTop: '20px'}}
        TabListProps={{sx: {borderBottom: 'none'}}}
        centered
      >
        <StyledTab
          label="Overview"
          variant="contained"
          sx={{textTransform: 'none', width: '33.33%'}}
          {...a11yProps(0)}
        />
        <StyledTab
          label="About"
          variant="contained"
          sx={{textTransform: 'none', width: '33.33%'}}
          {...a11yProps(1)}
        />
        <StyledTab
          label="Flavour"
          variant="contained"
          sx={{textTransform: 'none', width: '33.33%'}}
          {...a11yProps(2)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid container mt={4}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{color: '#323643', fontWeight: '600', fontSize: '14px'}}
            >
              Basic Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              style={{color: '#404642', fontWeight: '800', fontSize: '40px'}}
            >
              {userBagsObject?.bagOverview?.category[0]}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography
              variant="caption"
              style={{color: '#A3ADAF', fontWeight: '400', fontSize: '14px'}}
            >
              Botanical Name
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography
              variant="caption"
              style={{color: '#A3ADAF', fontWeight: '400', fontSize: '14px'}}
            >
              {userBagsObject?.bagOverview?.botanicalName[0]}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={5}>
            <Typography
              variant="h4"
              style={{color: '#121212', fontWeight: '400', fontSize: '30px'}}
            >
              {userBagsObject?.bagOverview?.Grade}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Typography
              variant="h4"
              style={{color: '#121212', fontWeight: '400', fontSize: '20px'}}
            >
              {userBagsObject?.bagOverview?.preparation}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography
              variant="caption"
              style={{color: '#5D6F88', fontWeight: '400', fontSize: '16px'}}
            >
              {userBagsObject?.aboutBag?.Description}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography
              variant="caption"
              style={{color: '#121212', fontWeight: '400', fontSize: '14px'}}
            >
              Size
            </Typography>
          </Grid>
          {userBagsObject?.bagOverview?.Size ? (
            <Box
              sx={{
                display: 'flex !important',
                justifyContent: 'center !important',
                alignItems: 'center !important',
                width: '70px !important',
                height: '40px !important',
                backgroundColor: 'black !important',
                color: 'white !important',
                marginBottom: '20px !important',
                marginTop: '10px !important',
              }}
            >
              <span
                style={{
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                {userBagsObject?.bagOverview?.Size}
              </span>
            </Box>
          ) : null}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container mt={4}>
          <Grid
            item
            xs={12}
            container
            m={0}
            py={3}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid
              item
              xs={12}
              variant="body1"
              m={0}
              pl={1}
              display="flex"
              alignItems="center"
              style={{justifyContent: 'start'}}
            >
              <div>
                <Typography
                  variant="h6"
                  style={{
                    color: '#323643',
                    // font: "Inter",
                    fontWeight: '600  ',
                    fontSize: '20px',
                  }}
                >
                  Source Details
                </Typography>
              </div>
              <InfoIcon
                onClick={() => {
                  handleSourcePopUpOpen();
                }}
                sx={{
                  padding: 1,
                  color: '#2D9CDB',
                  fontSize: '1.8rem',
                  cursor: 'pointer',
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            // sx={{ boxShadow: 3 }}
            py={4}
            style={{borderRadius: '15px'}}
            elevation={12}
            sx={{
              // margin: 2,
              // padding: 2,
              // border: "1px solid black",
              boxShadow: '0px 15px 20px -5px rgb(0 0 0 / 20%)',
            }}
            // sx={{ }}
          >
            <Grid item xs={4}>
              <div
                m={0}
                py={10}
                display="flex"
                alignItems="flex-start"
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <div container direction="column">
                  <Typography
                    variant="h6"
                    style={{
                      color: '#323643',
                      fontWeight: '200',
                      fontSize: '15px',
                    }}
                  >
                    Net Weight
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#323643',
                      fontWeight: '600',
                      fontSize: '20px',
                    }}
                  >
                    {userBagsObject?.aboutBag?.ntWeight} Kgs
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                m={0}
                pt={3}
                display="flex"
                alignItems="flex-start"
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <div container direction="column">
                  <Typography
                    variant="h6"
                    style={{
                      color: '#323643',
                      fontWeight: '200',
                      fontSize: '15px',
                    }}
                  >
                    Price/ Kg
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#323643',
                      fontWeight: '600',
                      fontSize: '20px',
                    }}
                  >
                    Rs {userBagsObject?.aboutBag?.price}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div
                m={0}
                pt={3}
                display="flex"
                alignItems="flex-start"
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <div container direction="column">
                  <Typography
                    variant="h6"
                    style={{
                      color: '#323643',
                      font: 'SF Pro Display',
                      fontWeight: '200',
                      fontSize: '15px',
                    }}
                  >
                    Moisture %
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{
                      color: '#323643',
                      fontWeight: '600',
                      fontSize: '20px',
                    }}
                  >
                    {userBagsObject?.aboutBag?.moisture}
                  </Typography>
                </div>
              </div>
            </Grid>
            {/* </Box> */}
          </Grid>
          <Grid item xs={12} mt={7} ml={3}>
            <Typography
              variant="caption"
              style={{
                color: '#333333',
                // font: "SF Pro Display",
                fontWeight: '400',
                fontSize: '18px',
              }}
            >
              Key Features
            </Typography>
          </Grid>
          <Grid item xs={12} mt={0} ml={3}>
            <Typography
              variant="caption"
              style={{
                color: '#333333',
                font: 'SF Pro Display',
                fontWeight: '800',
                fontSize: '25px',
                // letterHeight: "24px",
                // lineHeight: "24px",
                // letterSpacing: "0.2 px",
              }}
            >
              {userBagsObject?.aboutBag?.packingType}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={0} ml={3} pb={3}>
            <Box width={'220px'}>
              <Typography
                variant="caption"
                style={{
                  color: '#333333',
                  font: 'SF Pro Display',
                  fontWeight: '400',
                  fontSize: '18px',
                  letterHeight: '24px',
                  lineHeight: '22px',
                  letterSpacing: '0.2 px',
                }}
              >
                Buy Date:{' '}
                {getFormattedDate(userBagsObject?.receiptRefrence?.dateCreated)}{' '}
                Best Before :
                {getFormattedDate(userBagsObject?.aboutBag?.harvestDate)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container mt={4} mb={3}>
          <Grid
            item
            container
            direction={'row'}
            display="flex"
            alignItems="center"
            pl={3}
            my={3}
          >
            <Typography
              style={{
                color: '#323643',
                font: 'Inter',
                fontWeight: '600',
                fontSize: '20px',
              }}
            >
              Flavour Wheel
            </Typography>
            <InfoIcon
              onClick={() => {
                handleFlavourWheelPopUpOpen();
              }}
              sx={{color: '#2D9CDB', padding: '5px', fontSize: '1.8rem'}}
            />
          </Grid>
          {hasValueGreaterThanZero ? (
            <Grid item xs={12} mt={10} pl={4}>
              <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="donut"
                height={350}
              />
            </Grid>
          ) : null}

          {/* <Grid
            item
            container
            direction={"row"}
            display="flex"
            alignItems="center"
            style={{ justifyContent: "start" }}
            pl={0}
          >

            <div
              id="chart"
              style={{
                width: "100%",
                margin: "5px auto",
                // justifyContent: "start",
              }}
            ></div>
          </Grid> */}
          {!userBagsObject?.userData?.Organization?.isFilesPublic ? (
            <>
              <Grid
                item
                container
                direction={'row'}
                display="flex"
                alignItems="center"
                pl={3}
                mb={3}
              >
                <Typography
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '600',
                    fontSize: '20px',
                  }}
                >
                  Flavour Report
                </Typography>
              </Grid>
              <Grid
                item
                container
                display="flex"
                alignItems="center"
                // pl={3}
                xs={12}
                direction="row"
                px={3}
                py={2}
                mb={8}
                style={{borderRadius: '5px'}}
                sx={{boxShadow: '0px 7px 8px -6px rgb(0 0 0 / 20%)'}}
              >
                <Grid
                  item
                  xs={9}
                  variant="body1"
                  display="flex"
                  alignItems="center"
                  style={{justifyContent: 'start'}}
                >
                  <div
                    py={1}
                    display="flex"
                    alignItems="center"
                    style={{
                      justifyContent: 'start',
                    }}
                  >
                    <Typography
                      id="size-small"
                      // defaultValue="Cupping Report Pdf"
                      size="small"
                      width={'100%'}
                      style={{
                        // border: "none",
                        width: '100%',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        paddingBottom: '13px',
                        paddingTop: '13px',
                        color: '#323643',
                        font: 'Inter',
                        fontWeight: '400',
                        fontSize: '18px',
                        lineHeight: '22px',
                      }}
                    >
                      Cupping Report Pdf
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  variant="body1"
                  display="flex"
                  alignItems="center"
                  style={{justifyContent: 'end'}}
                >
                  <div
                    // p={16 28}
                    display="flex"
                    alignItems="center"
                    style={{
                      border: '1px solid',
                      borderColor: '#2D9CDB',
                      borderRadius: '5px',
                      justifyContent: 'end',
                    }}
                  >
                    <Button
                      style={{padding: '4px 16px'}}
                      onClick={() =>
                        window.open(userBagsObject?.report, '_blank')
                      }
                    >
                      VIEW
                    </Button>
                  </div>
                </Grid>
                {/* <InfoIcon sx={{ color: "#006EFF", padding: "5px" }} /> */}
              </Grid>
            </>
          ) : null}
        </Grid>
      </TabPanel>
      <Popup
        open={showPopup}
        title={popUpTitle}
        message={popUpMessage}
        onClose={handleClose}
      />
    </>
  );
}
