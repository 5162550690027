import React, {useState} from 'react';
import * as yup from 'yup';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import AvatarEditor from 'react-avatar-editor';
import CameraIcon from '@material-ui/icons/Camera';
import DeleteIcon from '@material-ui/icons/Delete';
import firebase, {db, storage} from '../../firebase_setup/firebase';

export default function UserRegistration({initialValues}) {
  const [profilePicture, setProfilePicture] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);

  const registrationFormValidationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
    mobile: yup.string().required('Mobile is required'),
    organizationName: yup.string().required('Organization Name is required'),
    organizationType: yup.string().required('Organization Type is required'),
    taxId: yup.string().required('Tax Id is required'),
    otp: yup.string().test({
      name: 'otp',
      test: function (value) {
        // Access form values using `this.parent`
        const mobile = this.parent.mobile;
        const otpSent = this.parent.otpSent;

        // Check the condition and return true if the validation should pass
        if (otpSent) {
          return !!value || this.createError({message: 'OTP is required'});
        }

        // If OTP is not sent, no validation is needed
        return true;
      },
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      organizationName: '',
      taxId: '',
      organizationType: '',
      otp: '',
    },
    enableReinitialize: true,
    validationSchema: registrationFormValidationSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        if (otpSent) {
          // Validate OTP using Firebase
          const credential = firebase.auth.PhoneAuthProvider.credential(
            confirmationResult.verificationId,
            values.otp,
          );

          const userCredential = await firebase
            .auth()
            .signInWithCredential(credential);
          const user = userCredential.user;
          const uid = user.uid;

          const documentData = {
            uid: uid,
            display_name: values.name,
            email: values.email,
            phone_number: `+${values.mobile}`,
            Organization: {
              name: values.organizationName,
              OrganizationType: values.organizationType,
              TaxId: values.taxId,
            },
            photo_url: profilePicture,
          };

          const userCollection = db.collection('users');
          const userDocument = userCollection.doc(uid);

          // Set the document data
          userDocument
            .set(documentData)
            .then(() => {
              toast.success('User created successfully!', {
                position: 'top-right',
                autoClose: 3000, // Auto-close the toaster after 3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              resetForm();
              handleRemoveImage();
              setOtpSent(false);
              setConfirmationResult(null);
            })
            .catch(error => {
              console.error('Error writing document: ', error);
            });
        } else {
          // Send OTP
          const confirmation = await firebase
            .auth()
            .signInWithPhoneNumber(
              `+${values.mobile}`,
              new firebase.auth.RecaptchaVerifier('recaptcha-container'),
            );

          // Set confirmation result and OTP sent flag
          setConfirmationResult(confirmation);
          setOtpSent(true);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
        toast.error(error?.message || 'Invalid OTP', {
          position: 'top-right',
          autoClose: 3000, // Auto-close the toaster after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
  });

  const handleImageChange = async e => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setProfilePicture(URL.createObjectURL(file));

      // Trigger the upload immediately after selecting the image
      await uploadImage(file);
    }
  };

  const uploadImage = async file => {
    const storageRef = storage.ref('profilePictures').child(file.name);

    try {
      // Upload the file to Firebase Storage
      await storageRef.put(file);

      // Get the download URL of the uploaded image
      const url = await storageRef.getDownloadURL();
      console.log(url);
      setProfilePicture(url);
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };
  const handleResendOTP = async () => {
    try {
      // Resend OTP
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(
          `+${formik.values.mobile}`,
          new firebase.auth.RecaptchaVerifier('recaptcha-container'),
        );

      // Set confirmation result and OTP sent flag
      setConfirmationResult(confirmation);
      setOtpSent(true);
    } catch (error) {
      console.error('Error during OTP resend:', error);
    }
  };

  const handleRemoveImage = () => {
    setProfilePicture('');
    setImage(null);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{minHeight: '100vh', justifyContent: 'center'}}
      >
        <Grid item xs={10} sm={8} md={6} lg={6}>
          <Paper elevation={3} style={{padding: '20px', textAlign: 'center'}}>
            <Typography variant="h4" style={{marginBottom: '1.35em'}}>
              User Registration
            </Typography>
            <form onSubmit={formik.handleSubmit} id="registrationForm">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{position: 'relative', marginBottom: '10px'}}>
                    {profilePicture ? (
                      <>
                        <AvatarEditor
                          ref={ref => setEditor(ref)}
                          image={profilePicture}
                          width={80}
                          height={80}
                          border={50}
                          borderRadius={50}
                        />
                        <IconButton
                          color="secondary"
                          style={{
                            position: 'absolute',
                          }}
                          title="Remove Image"
                          onClick={handleRemoveImage}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <input
                          id="profilePictureInput"
                          type="file"
                          accept="image/*"
                          style={{display: 'none'}}
                          onChange={handleImageChange}
                        />
                        <label htmlFor="profilePictureInput">
                          <IconButton color="primary" component="span">
                            <CameraIcon />
                          </IconButton>
                        </label>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Full Name"
                    fullWidth
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    fullWidth
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Enter Full Mobile No with country code(ex:9198xxx49)"
                    fullWidth
                    name="mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mobile && Boolean(formik.errors.mobile)
                    }
                    helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Organization Name"
                    fullWidth
                    name="organizationName"
                    value={formik.values.organizationName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.organizationName &&
                      Boolean(formik.errors.organizationName)
                    }
                    helperText={
                      formik.touched.organizationName &&
                      formik.errors.organizationName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Organization Type (ex: LLP)"
                    fullWidth
                    name="organizationType"
                    value={formik.values.organizationType}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.organizationType &&
                      Boolean(formik.errors.organizationType)
                    }
                    helperText={
                      formik.touched.organizationType &&
                      formik.errors.organizationType
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="TAX ID (TAN/ GST/PAN/ TIN etc)"
                    fullWidth
                    name="taxId"
                    value={formik.values.taxId}
                    onChange={formik.handleChange}
                    error={formik.touched.taxId && Boolean(formik.errors.taxId)}
                    helperText={formik.touched.taxId && formik.errors.taxId}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* OTP input */}
                  {otpSent && (
                    <>
                      <TextField
                        label="OTP"
                        fullWidth
                        name="otp"
                        value={formik.values.otp}
                        onChange={formik.handleChange}
                        error={formik.touched.otp && Boolean(formik.errors.otp)}
                        helperText={formik.touched.otp && formik.errors.otp}
                      />
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleResendOTP}
                      >
                        Resend OTP
                      </Button>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/* Recaptcha container */}
                  <div id="recaptcha-container"></div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{marginRight: '10px'}}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={formik.handleReset}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
}
