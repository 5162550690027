import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import './ViewBagsPage.css';
import React from 'react';
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import {Helmet} from 'react-helmet';
import BagSpecifications from './BagSpecifications/BagSpecifications';
import BagOriginTrail from './BagOriginTrail/BagOriginTrail';
import {makeStyles} from '@mui/styles';
import db from '../../firebase_setup/firebase';

const StyledTab = styled(Tab)(({theme}) => ({
  borderRadius: 0,
  backgroundColor: 'white',
  height: '30px',
  width: '50%',
  minHeight: '30px',
  textTransform: 'none',
  color: theme.palette.primary.main,
  '&.Mui-selected': {
    backgroundColor: '#2D9CDB',
    color: 'white',
    borderRadius: '5px',
  },
}));

function TabPanel({value, index, children}) {
  return (
    <Box hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </Box>
  );
}

export default function ViewBagsPage() {
  const params = useParams();
  console.log();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const myQueryParam = queryParams.get('isDistributor');

  const [value, setValue] = useState(0);
  const [notValidQr, setNotValidQr] = useState(false);
  const [userBagsObject, setUserBagsObject] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async paramsData => {
    const documentSnapshot = await db
      .collection('users')
      .doc(paramsData[0])
      .collection('Bags')
      .doc(paramsData[1])
      .get();
    if (documentSnapshot.exists) {
      // Document exists, retrieve the data
      let userBagsData = documentSnapshot.data();
      const receiptReference = userBagsData.receiptRefrence;
      const sellerReference = userBagsData.sellerRefrence;
      const folderReference = userBagsData.bagOverview.folderrefrence;
      if (receiptReference) {
        const receiptDocumentSnapshot = await receiptReference.get();
        if (receiptDocumentSnapshot.exists) {
          // Receipt document exists, retrieve the data
          userBagsData.receiptRefrence = receiptDocumentSnapshot.data();
          const receiptSellerReference =
            userBagsData.receiptRefrence.SellerRefrence;
          const receiptSellerDocumentSnapshot =
            await receiptSellerReference.get();
          if (receiptSellerDocumentSnapshot.exists) {
            userBagsData.receiptRefrence.SellerRefrence =
              receiptSellerDocumentSnapshot.data();
          } else {
            console.log('Receipt seller document does not exist');
          }
        } else {
          // Receipt document does not exist
          console.log('Receipt document does not exist');
        }
      } else {
        // Document does not exist
        console.log('Document does not exist');
      }

      if (sellerReference) {
        const sellerDocumentSnapshot = await sellerReference.get();
        if (sellerDocumentSnapshot.exists) {
          userBagsData.sellerRefrence = sellerDocumentSnapshot.data();
        }
      }

      if (folderReference) {
        const folderDocumentSnapshot = await folderReference.get();
        if (folderDocumentSnapshot.exists) {
          userBagsData.bagOverview.folderrefrence =
            folderDocumentSnapshot.data();
        }
        const cropGroupReference =
          userBagsData.bagOverview.folderrefrence.CropGroup;
        if (cropGroupReference) {
          const cropGroupDocumentSnapshot = await cropGroupReference.get();
          if (cropGroupDocumentSnapshot.exists) {
            userBagsData.bagOverview.folderrefrence.CropGroup =
              cropGroupDocumentSnapshot.data();
          }
        }
      }

      const documentUserSnapshot = await db
        .collection('users')
        .doc(paramsData[0])
        .get();
      if (documentUserSnapshot.exists) {
        userBagsData = {...userBagsData, userData: documentUserSnapshot.data()};
      }
      console.log(userBagsData)
      setUserBagsObject(userBagsData);
    }
  };
  useEffect(() => {
    const paramsData = params.id.split('|');
    if (paramsData && paramsData.length === 3) {
      fetchData(paramsData);
    } else {
      setNotValidQr(true);
    }
  }, [params]);

  return (
    <>
      {!notValidQr ? (
        <>
          <Helmet>
            <title> View Bag</title>
          </Helmet>
          <Container maxWidth="xl">
            <div>
              <Grid>
                <Typography
                  variant="h6"
                  marginLeft={1}
                  style={{fontWeight: 600}}
                >
                  View Bag
                </Typography>
              </Grid>

              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  UID: {userBagsObject?.bagId}
                </Typography>
              </Grid>

              <Tabs
                value={value}
                onChange={handleChange}
                style={{marginTop: '30px'}}
                sx={{height: '30px', minHeight: '30px'}}
                TabListProps={{sx: {borderBottom: 'none'}}}
                centered
              >
                <StyledTab
                  label="Specifications"
                  variant="contained"
                  sx={{textTransform: 'none'}}
                />
                <StyledTab
                  label="Origin Trail"
                  variant="contained"
                  sx={{textTransform: 'none'}}
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <BagSpecifications userBagsObject={userBagsObject} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <BagOriginTrail userBagsObject={userBagsObject} />
              </TabPanel>
            </div>
          </Container>
        </>
      ) : null}
    </>
  );
}
