import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import React from 'react';
import {Avatar} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import {shadows} from '@mui/system';
import ApexCharts from 'apexcharts';

// ###################

// ###################

import {makeStyles} from '@mui/styles';
import {
  Box,
  Chip,
  Slider,
  TextField,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';

import {LoadScript, GoogleMap, Marker} from '@react-google-maps/api';
import TeamCard from '../../../components/teamCard/TeamCard';
import ReactApexChart from 'react-apexcharts';
import {defaultImageUrl} from '../../../utils/constants';
import db from '../../../firebase_setup/firebase';

const useStyles = makeStyles({
  square: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70px',
    height: '40px',
    backgroundColor: 'black',
    color: 'white',
    marginBottom: '20px',
    marginTop: '10px',
  },

  dateBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    height: '40px',
    marginTop: '10px',
    borderRadius: '4px',
    border: `3px solid #B8C0C3`,
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
  },
});

const StyledTab = styled(Tab)(({theme}) => ({
  borderRadius: 0,
  backgroundColor: 'white',
  height: '33px',
  width: '50%',
  minHeight: '33px',
  textTransform: 'none',
  color: theme.palette.primary.main,
  '&.Mui-selected': {
    backgroundColor: '#2D9CDB',
    color: 'white',
    borderRadius: '5px',
  },
}));

function TabPanel({value, index, children}) {
  return (
    <Box hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </Box>
  );
}

const marks = [
  {
    value: 0,
    label: '1 Ltr',
  },
  {
    value: 100,
    label: '10000 Ltr',
  },
];

// function valuetext(value) {
//   return `${value}°C`;
// }

export default function BagOriginTrail({userBagsObject}) {
  const params = useParams();
  const [value, setValue] = useState(0);
  const [center, setCenter] = useState({lat: 20.0044396, lng: 73.7657524});
  const [membersData, setMembersData] = useState([]);

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const containerStyle = {
    width: '100%',
    height: '250px',
    borderRadius: '20px',
  };

  const options = {
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
    zoomControl: true,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getPercentageForSlider = waterUsage => {
    if (waterUsage < 1 || waterUsage > 10000) {
      return;
    }

    const percentage = (waterUsage / 10000) * 100;
    return percentage;
  };

  const chartOptions = {
    chart: {
      type: 'donut',
    },
    series: [44, 55, 13, 43, 22],
    labels: ['A', 'B', 'C', 'D', 'E'],
    colors: ['#A155B9', '#165BAA', '#F765A3', '#16BFD6', '#1DDD8D'],
    dataLabels: {
      enabled: false,
    },
  };

  const getFormattedDate = orderDate => {
    if (orderDate) {
      const date = new Date(
        orderDate.seconds * 1000 + orderDate.nanoseconds / 1000000,
      );
      const formattedDate =
        date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear(); //  change the format to your preferred date format
      return formattedDate;
    }
    return '';
  };

  const fetchMembers = async () => {
    const paramsData = params.id.split('|');
    const querySnapshot = await db
      .collection('users')
      .doc(paramsData[0])
      .collection('members')
      .get();
    const documentsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    setMembersData(documentsData);
  };
  console.log(userBagsObject);
  useEffect(() => {
    if (value == 1) {
      fetchMembers();
    }
  }, [value]);

  return (
    <>
      <Grid container mt={3}>
        <Grid
          item
          direction="column"
          xs={12}
          display="flex"
          alignItems="start"
          style={{justifyContent: 'center'}}
        >
          {value === 0 && (
            <>
              {!userBagsObject?.userData?.Organization?.videoVisiblity && (
                <video
                  width="100%"
                  height="100%"
                  src={userBagsObject?.userData?.Organization?.videoUrl}
                  type="video/webm"
                  controls
                  // autoplay="autoplay"
                  style={{borderRadius: '15px'}}
                >
                  <p>Your browser cannot play the provided video file.</p>
                </video>
              )}

              <Grid item xs={12} mt={2}>
                <Typography
                  variant="body1"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '24px',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.name}
                </Typography>
              </Grid>
            </>
          )}
          {value === 1 && (
            <>
              <Typography
                variant="body1"
                py={2}
                style={{
                  color: '#404642',
                  fontWeight: '600',
                  fontSize: '16px',
                }}
              >
                Geo-Location
              </Typography>
              {!window?.google && !window?.google?.maps ? (
                <LoadScript googleMapsApiKey="AIzaSyAumjYGgcBDGbthN3zp67FpiCMnOK6s_0Q">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    options={options}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
              ) : (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  options={options}
                >
                  <Marker position={center} />
                </GoogleMap>
              )}
            </>
          )}
          {value === 2 && (
            <>
              <Typography
                variant=""
                py={2}
                style={{
                  color: '#404642',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Plantation
              </Typography>
              {userBagsObject?.userData?.Organization?.plantationImage ? (
                <img
                  src={userBagsObject?.userData?.Organization?.plantationImage}
                  alt="My Image"
                  style={{
                    borderRadius: '25px',
                    width: '100%',
                    height: '227.22px',
                    objectFit: 'cover',
                  }}
                />
              ) : null}
            </>
          )}
        </Grid>
      </Grid>

      <Tabs
        value={value}
        onChange={handleChange}
        style={{marginTop: '20px'}}
        TabListProps={{sx: {borderBottom: 'none'}}}
        centered
      >
        <StyledTab
          label="Overview"
          variant="contained"
          sx={{textTransform: 'none', width: '33.33%'}}
          {...a11yProps(0)}
        />
        <StyledTab
          label="People"
          variant="contained"
          sx={{textTransform: 'none', width: '33.33%'}}
          {...a11yProps(1)}
        />
        <StyledTab
          label="Sustainability"
          variant="contained"
          sx={{textTransform: 'none', width: '33.33%'}}
          {...a11yProps(2)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid container mt={4}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{color: '#323643', fontWeight: '600', fontSize: '14px'}}
            >
              Organisation
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              style={{color: '#404642', fontWeight: '700', fontSize: '40px'}}
            >
              {userBagsObject?.userData?.Organization?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2} paddingRight="45px">
            <Typography
              variant="caption"
              style={{
                color: '#A3ADAF',
                fontWeight: '500',
                fontSize: '14px',
                paddingRight: '45px',
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              {userBagsObject?.userData?.Organization?.Describe}
            </Typography>
          </Grid>
          {!userBagsObject?.userData?.Organization?.isBasicInfoPublic ? (
            <>
              <Grid item xs={12} mt={2}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#A3ADAF',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Basic Info
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={2}
                container
                direction="row"
                py={4}
                style={{borderRadius: '15px'}}
                elevation={12}
                sx={{
                  boxShadow: '0px 5px 20px -5px rgb(0 0 0 / 20%)',
                }}
              >
                <Grid item xs={4}>
                  <div
                    m={0}
                    py={10}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div container direction="column">
                      <Typography
                        variant="h6"
                        style={{
                          color: '#A3ADAF',
                          fontWeight: '200',
                          fontSize: '15px',
                        }}
                      >
                        Size (in ha)
                      </Typography>
                      <Typography
                        variant="h2"
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '20px',
                        }}
                      >
                        {userBagsObject?.userData?.Organization?.sizeInHa}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div container direction="column">
                      <Typography
                        variant="h6"
                        style={{
                          color: '#A3ADAF',
                          fontWeight: '200',
                          fontSize: '15px',
                        }}
                      >
                        Elevation (Est)
                      </Typography>
                      <Typography
                        variant="h2"
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '20px',
                        }}
                      >
                        {userBagsObject?.userData?.Organization?.elevation} mtrs
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-center"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div container direction="column">
                      <Typography
                        variant="h6"
                        style={{
                          color: '#A3ADAF',
                          font: 'SF Pro Display',
                          fontWeight: '200',
                          fontSize: '15px',
                        }}
                      >
                        Rainfall (in cms)
                      </Typography>
                      <Typography
                        variant="h2"
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {userBagsObject?.userData?.Organization?.rainfallCms}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : null}
          {!userBagsObject?.userData?.Organization?.isHarvestPeriodPublic ? (
            <>
              <Grid item xs={12} mt={5}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#121212',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  Harvest Period
                </Typography>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#121212',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Main
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={2}
                container
                direction="row"
                // py={4}
                // style={{ borderRadius: "15px" }}
                // elevation={12}
                // sx={{
                //   boxShadow: "0px 5px 20px -5px rgb(0 0 0 / 20%)",
                // }}
              >
                <Grid item xs={6}>
                  <div
                    m={0}
                    py={10}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'start'}}
                  >
                    <Typography
                      variant="h6"
                      pl={2}
                      style={{
                        color: '#A3ADAF',
                        fontWeight: '200',
                        fontSize: '15px',
                      }}
                    >
                      Start
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'start'}}
                  >
                    <Typography
                      variant="h6"
                      pl={2}
                      style={{
                        color: '#A3ADAF',
                        fontWeight: '200',
                        fontSize: '15px',
                      }}
                    >
                      End
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                mt={0}
                container
                direction="row"
                // py={4}
                // style={{ borderRadius: "15px" }}
                // elevation={12}
                // sx={{
                //   boxShadow: "0px 5px 20px -5px rgb(0 0 0 / 20%)",
                // }}
              >
                <Grid item xs={6}>
                  <div
                    m={0}
                    py={10}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div
                      style={{
                        border: '1px solid ',
                        borderRadius: '5px',
                        borderColor: '#A3ADAF',
                        width: '100%',
                        // padding: "auto",
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        // variant="h5"
                        py={1}
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        {getFormattedDate(
                          userBagsObject?.userData?.Organization?.mainStartDate,
                        )}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div
                      style={{
                        border: '1px solid ',
                        borderRadius: '5px',
                        borderColor: '#A3ADAF',
                        width: '100%',
                        // padding: "auto",
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        // variant="h5"
                        py={1}
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        {getFormattedDate(
                          userBagsObject?.userData?.Organization?.mainEndDate,
                        )}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#121212',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Fly
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={2}
                container
                direction="row"
                // py={4}
                // style={{ borderRadius: "15px" }}
                // elevation={12}
                // sx={{
                //   boxShadow: "0px 5px 20px -5px rgb(0 0 0 / 20%)",
                // }}
              >
                <Grid item xs={6}>
                  <div
                    m={0}
                    py={10}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'start'}}
                  >
                    <Typography
                      variant="h6"
                      pl={2}
                      style={{
                        color: '#A3ADAF',
                        fontWeight: '200',
                        fontSize: '15px',
                      }}
                    >
                      Start
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'start'}}
                  >
                    <Typography
                      variant="h6"
                      pl={2}
                      style={{
                        color: '#A3ADAF',
                        fontWeight: '200',
                        fontSize: '15px',
                      }}
                    >
                      End
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                mt={0}
                container
                direction="row"
                // py={4}
                // style={{ borderRadius: "15px" }}
                // elevation={12}
                // sx={{
                //   boxShadow: "0px 5px 20px -5px rgb(0 0 0 / 20%)",
                // }}
              >
                <Grid item xs={6}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div
                      style={{
                        border: '1px solid ',
                        borderRadius: '5px',
                        borderColor: '#A3ADAF',
                        width: '100%',
                        // padding: "auto",
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        // variant="h5"
                        py={1}
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        {getFormattedDate(
                          userBagsObject?.userData?.Organization?.flyStartDate,
                        )}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    m={0}
                    pt={3}
                    display="flex"
                    alignItems="flex-start"
                    style={{display: 'flex', justifyContent: 'center'}}
                  >
                    <div
                      style={{
                        border: '1px solid ',
                        borderRadius: '5px',
                        borderColor: '#A3ADAF',
                        width: '100%',
                        // padding: "auto",
                        margin: '0px 5px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        // variant="h5"
                        py={1}
                        style={{
                          color: '#323643',
                          fontWeight: '600',
                          fontSize: '14px',
                        }}
                      >
                        {getFormattedDate(
                          userBagsObject?.userData?.Organization?.endFlyDate,
                        )}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : null}
          {!userBagsObject?.userData?.Organization?.isOnsitefacilitiesPublic ? (
            <>
              <Grid item xs={12} mt={5}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#121212',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  On-Site Facilities
                </Typography>
              </Grid>
              <Grid item xs={12} mt={4} mb={4} container direction="row">
                <div
                  m={0}
                  display="flex"
                  alignItems="flex-start"
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    overflow: 'scroll',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.oonSiteFacilities &&
                  userBagsObject?.userData?.Organization?.oonSiteFacilities
                    .length > 0
                    ? userBagsObject?.userData?.Organization?.oonSiteFacilities.map(
                        res => {
                          return (
                            <Chip
                              label={res}
                              margin="0px 10px"
                              style={{
                                color: '#fff',
                                fontWeight: '700',
                                padding: '0px 10px',
                                backgroundColor: '#006EFF',
                                fontSize: '10px',
                                marginRight: '10px',
                              }}
                            />
                          );
                        },
                      )
                    : null}
                </div>
              </Grid>
            </>
          ) : null}
          {!userBagsObject?.userData?.Organization?.cropMixVisiblity ? (
            <>
              <Grid item xs={12} mt={0}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#121212',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  Crop Mix
                </Typography>
              </Grid>
              <Grid item xs={12} mt={4} mb={4} container direction="row">
                <div
                  m={0}
                  display="flex"
                  alignItems="flex-start"
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    overflow: 'scroll',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.cropMix &&
                  userBagsObject?.userData?.Organization?.cropMix.length > 0
                    ? userBagsObject?.userData?.Organization?.cropMix.map(
                        res => {
                          return (
                            <Chip
                              label={res}
                              margin="0px 10px"
                              style={{
                                color: '#fff',
                                fontWeight: '700',
                                padding: '0px 10px',
                                backgroundColor: '#006EFF',
                                fontSize: '10px',
                                marginRight: '10px',
                              }}
                            />
                          );
                        },
                      )
                    : null}
                </div>
              </Grid>
            </>
          ) : null}

          {/* <Grid item xs={12}>
            <Grid contianer>
              <Grid
                item
                xs={12}
                mt={5}
                pl={4}
                display={'flex'}
                alignItems={'center'}
              >
                <Grid
                  item
                  xs={6}
                  // mt={5}
                  // pl={5}
                  display={'flex'}
                  alignItems={'center'}
                  style={{display: 'flex', justifyContent: 'start'}}
                >
                  <div style={{display: 'flex', justifyContent: 'start'}}>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#828282',
                        fontWeight: '600',
                        fontSize: '16px',
                      }}
                    >
                      Total Harvest (Est)
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  // mt={5}
                  // pl={5}
                  display={'flex'}
                  alignItems={'center'}
                  style={{display: 'flex', justifyContent: 'end'}}
                >
                  <div style={{display: 'flex', justifyContent: 'end'}}>
                    <InfoIcon sx={{padding: 1, color: '#BDBDBD'}} />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={0} pl={4}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#0B1354',
                    fontWeight: '500',
                    fontSize: '40px',
                  }}
                >
                  5,987.34
                </Typography>
              </Grid>
              <Grid item xs={12} mt={0} pl={4}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#4F4F4F',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Metric Tonnes
                </Typography>
              </Grid>
              <Grid item xs={12} mt={10} pl={4}>
                <ReactApexChart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="donut"
                  height={350}
                />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container mt={4}>
          <Grid
            item
            xs={12}
            container
            m={0}
            pl={1}
            pt={3}
            direction="row"
            justifyContent="flex-start"
          >
            <Typography
              variant="h6"
              style={{
                color: '#323643',
                fontWeight: '600  ',
                fontSize: '20px',
              }}
            >
              Grower
            </Typography>
          </Grid>
          <Grid container style={{marginTop: '20px'}}>
            <Grid item xs={3}>
              <Avatar
                sx={{width: 70, height: 70}}
                src={
                  userBagsObject?.sellerRefrence?.SellerName?.image ||
                  defaultImageUrl
                }
                alt="photoURL"
              />
            </Grid>
            <Grid item xs={9} style={{display: 'flex', alignItems: 'center'}}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h6" className={classes.ellipsis}>
                    {userBagsObject?.sellerRefrence?.SellerName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" className={classes.ellipsis}>
                    Farm Size: {userBagsObject?.sellerRefrence?.farmSize} Ha
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} pl={1} pb={3} style={{alignItems: 'end'}}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                className={classes.ellipsis}
                style={{color: '#323643', fontWeight: 200}}
              >
                Crops Grown
              </Typography>
              <Typography
                variant="body1"
                className={classes.ellipsis}
                style={{color: '#323643', fontWeight: 500}}
              >
                {userBagsObject?.sellerRefrence?.CropList
                  ? userBagsObject?.sellerRefrence?.CropList.toString()
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                className={classes.ellipsis}
                style={{color: '#323643', fontWeight: 200}}
              >
                Harvest Date
              </Typography>
              <Box className={classes.dateBox}>
                <Typography variant="body1" className={classes.ellipsis}>
                  16-02-2022
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            m={0}
            pl={1}
            py={3}
            direction="row"
            justifyContent="flex-start"
          >
            <Typography
              variant="h6"
              style={{
                color: '#323643',
                fontWeight: '600  ',
                fontSize: '20px',
              }}
            >
              The Team
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <TeamCard membersData={membersData} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container mt={3}>
          <Grid
            item
            container
            direction={'row'}
            display="flex"
            alignItems="center"
            pl={3}
            mt={3}
          >
            <Typography
              style={{
                color: '#323643',
                fontWeight: '400',
                fontSize: '14px',
              }}
            >
              Region
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={'row'}
            display="flex"
            alignItems="center"
            pl={3}
            my={0}
          >
            <Typography
              style={{
                color: '#323643',
                font: 'Inter',
                fontWeight: '700',
                fontSize: '50px',
                // lineHeight: "50px",
              }}
            >
              {userBagsObject?.userData?.Organization?.AboutRegion}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction={'row'}
            display="flex"
            alignItems="center"
            pl={3}
            my={3}
          >
            <Typography
              style={{
                color: '#323643',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              {userBagsObject?.userData?.Organization?.Describe}
            </Typography>
          </Grid>
          {!userBagsObject?.userData?.Organization?.isLandManagementPublic ? (
            <>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '18px',
                  }}
                >
                  Land Management
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Farming Method
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={0}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.farmingMethod}
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={0}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Soil Management:{' '}
                  {userBagsObject?.userData?.Organization?.soilMaanagement}{' '}
                  <br></br> Pest & Disease Management:{' '}
                  {userBagsObject?.userData?.Organization?.pestMgt}
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Certification
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={3} alignItems={'center'}>
                <img
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  src="/assets/Certificates/Image.png"
                  alt="photoURL"
                ></img>
              </Grid>
            </>
          ) : null}

          {!userBagsObject?.userData?.Organization?.isWatermanagementPublic ? (
            <>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Water Management
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={2}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '400',
                    fontSize: '12px',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.pestMgt}
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={0}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '800',
                    fontSize: '20px',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.waterSource}
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={0}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    font: 'Inter',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Milling Method:{' '}
                  {userBagsObject?.userData?.Organization?.milingMethod}
                </Typography>
              </Grid>
              <Grid item xs={12} pl={4} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '400',
                    fontSize: '14px',
                  }}
                >
                  Water Usage (per Kg)
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={1}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Water
                </Typography>
              </Grid>
              <Grid item xs={12} px={5} mt={2}>
                <Slider
                  aria-label="Custom marks"
                  defaultValue={getPercentageForSlider(
                    userBagsObject?.userData?.Organization?.waterUsage,
                  )}
                  disabled
                  // step={10}
                  // getAriaValueText={valuetext}
                  // valueLabelDisplay="auto"
                  marks={marks}
                />
              </Grid>
            </>
          ) : null}
          {!userBagsObject?.userData?.Organization?.isWatermanagementPublic ? (
            <>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Other
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                >
                  People Management
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.peoplemanagement}
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={3}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  Ecology/ Human
                </Typography>
              </Grid>
              <Grid item xs={12} pl={3} mt={0} mb={5}>
                <Typography
                  variant="caption"
                  style={{
                    color: '#323643',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                >
                  {userBagsObject?.userData?.Organization?.ecology}
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>
      </TabPanel>
    </>
  );
}
