import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import React from 'react';
import axios from 'axios';

export default function SubscribePlan() {
  const params = useParams();
  console.log(params);

  const fetchData = async paramsData => {
    console.log('paramsData', paramsData);

    const inputData = {
      userId: paramsData[0],
      planId: paramsData[1],
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/phonepe`, inputData)
      .then(res => {
        const {data} = res;
        console.log(data);
        window.location.href = data.data.instrumentResponse.redirectInfo.url;
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    const paramsData = params.id.split('|');
    if (paramsData && paramsData.length === 2) {
      fetchData(paramsData);
    }
  }, [params]);

  return <></>;
}
