import {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import React from 'react';
import {defaultImageUrl} from '../../utils/constants';

import {
  Box,
  TextField,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
  Avatar,
} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
});

export default function TeamCard({membersData}) {
  const params = useParams();
  const location = useLocation();
  const classes = useStyles();
  const queryParams = new URLSearchParams(location.search);
  const myQueryParam = queryParams.get('isDistributor');

  return (
    <>
      {membersData.length > 0 &&
        membersData.map(member => {
          return (
            <Grid container style={{marginTop: '20px'}}>
              <Grid item xs={3}>
                <Avatar
                  sx={{width: 70, height: 70}}
                  src={member?.profilePhoto}
                  alt="photoURL"
                />
              </Grid>
              <Grid item xs={9} style={{display: 'flex', alignItems: 'center'}}>
                <Grid container direction="column">
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      lineHeight: '0',
                    }}
                  >
                    <Typography variant="h6" className={classes.ellipsis}>
                      {member?.name}
                    </Typography>
                    {/* <Typography variant="body1">Male</Typography> */}
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">{member?.about}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
}
