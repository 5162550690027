import {useRoutes} from 'react-router-dom';
// layouts
import React from 'react';
import ViewBagsPage from '../pages/viewBags/ViewBagsPage';
import ScanBagsQr from '../pages/ScanBagsQr/ScanBagsQr';
import SubscribePlan from '../pages/subscribePlan/SubscribePlan';
import PaymentStatus from '../pages/paymentStatus/PaymentStatus';
import UserRegistration from '../pages/userRegistration/userRegistration';

export default function Router() {
  const routes = useRoutes([
    {
      name: 'viewBag',
      path: '/:id',
      element: <ViewBagsPage />,
    },
    {
      name: 'registerUser',
      path: '/user/register',
      element: <UserRegistration />,
    },
    {
      name: 'scanQr',
      path: '/',
      element: <ScanBagsQr />,
    },
    {
      name: 'planSubscription',
      path: '/subscribe/:id',
      element: <SubscribePlan />,
    },
    {
      name: 'paymentStatus',
      path: '/status',
      element: <PaymentStatus />,
    },
  ]);

  return routes;
}
